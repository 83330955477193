import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"
import { FaFacebookF, FaTwitter } from "react-icons/fa";


import { useSpring, animated } from 'react-spring';



const CollapseMenu = (props) => {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 });

  if (props.navbarState === true) {
    return (
      
      <CollapseWrapper style={{
        transform: open.interpolate({
          range: [0, 0.2, 0.3, 1],
          output: [0, -20, 0, -250],
        }).interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
      }} 
      >
        <NavLinks>
          <li><NavLinkButton activeStyle={{ color: "#00aeef" }} to="/about" onClick={props.handleNavbar}>About</NavLinkButton></li>
          <li><NavLinkButton activeStyle={{ color: "#00aeef" }} to="/blog" onClick={props.handleNavbar}>Blog</NavLinkButton></li>
          <li><NavLinkButton activeStyle={{ color: "#00aeef" }} to="/contact" onClick={props.handleNavbar}>Contact</NavLinkButton></li>
          <ul style={{ display: "flex", listStyle: "none", fontSize: "2rem", marginLeft: "0", marginTop: "20px", color: "#0a3b7c" }}>
            <li><a className="social-links" style={{ color: "#0a3b7c" }} href="https://www.facebook.com/GaryHyndsLisburn"><FaFacebookF /></a></li>
            <li ><a className="social-links" style={{ marginLeft: "20px" }} href="https://twitter.com/GaryHynds"><FaTwitter /></a></li>
          </ul>
        </NavLinks>
      </CollapseWrapper>
      
    );
  }
  return (
    
    <CollapseWrapper style={{
      transform: open.interpolate({
        range: [0, 0.2, 0.3, 1],
        output: [0, -20, 0, -250],
      }).interpolate(openValue => `translate3d(0, ${openValue}px, 0`),
    }} 
    >
      <NavLinks>
        <li><NavLinkButton activeStyle={{ color: "#00aeef" }} to="/about" onClick={props.handleNavbar}>About</NavLinkButton></li>
        <li><NavLinkButton activeStyle={{ color: "#00aeef" }} to="/blog" onClick={props.handleNavbar}>Blog</NavLinkButton></li>
        <li><NavLinkButton activeStyle={{ color: "#00aeef" }} to="/contact" onClick={props.handleNavbar}>Contact</NavLinkButton></li>
        <ul style={{ display: "flex", listStyle: "none", fontSize: "2rem", marginLeft: "0", color: "#0a3b7c" }}>
            <li><a className="social-links" style={{ color: "#0a3b7c" }} href="https://www.facebook.com/GaryHyndsConservative/"><FaFacebookF /></a></li>
            <li ><a className="social-links" style={{ marginLeft: "20px" }} href="https://twitter.com/GaryHynds"><FaTwitter /></a></li>
          </ul>
      </NavLinks>
    </CollapseWrapper>
    
  );
};

export default CollapseMenu;

const CollapseWrapper = styled(animated.div)`
  background: #f2f2f2;
  width: 100vw;
  position: fixed;
  top: 4.5rem;
  left: 0;
  right: 0;
  padding-top: 20px;
  box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.13);
  @media (max-width: 768px) {
        z-index: 20;
    }
`;

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 0rem 2rem;
  margin-bottom: 0;

  .social-links {
    padding-top: .7rem;
    padding-left: .7rem;
    padding-right: .7rem;
    padding-bottom: .1rem;
    background-color: #707070;
    color: #FFFFFF !important;
    border-radius: 4px;
  }
  
`;

const NavLinkButton = styled(Link)`
    font-size: 1.4rem;
    line-height: 2;
    color: #0a3b7c;
    text-decoration: none;
    cursor: pointer;
    font-weight: 600;
    
`
