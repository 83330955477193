import React from 'react';
import styled from "styled-components";



const Burgermenu = (props) => {
  return (
    
    <Wrapper onClick={props.handleNavbar}>
      <div className={ props.navbarState ? "open" : "" }>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span style={{ marginBottom: "0" }}>&nbsp;</span>
      </div>
    </Wrapper>
    
  );
}

export default Burgermenu;

const Wrapper = styled.div`
  position: relative;
  padding: .7rem;
  cursor: pointer;
  display: block;
  & div {
    background: #f2f2f2;
    background-color: #f2f2f2;
    padding: .7rem;
    border-radius: 2px;
  }
  & span {
    background: #0a3b7c !important;
    display: block;
    position: relative;
    width: 3.5rem;
    height: .4rem;
    margin-bottom: .7rem;
    transition: all ease-in-out 0.2s;
    border-radius: 5px;
  }

  .open {
    background-color: #00aeef;
  }

  .open span {
    background: #FFFFFF !important;
  }

  .open span:nth-child(2) {
      opacity: 0;
    }
  .open span:nth-child(3) {
    transform: rotate(45deg);
    top: -11px;
  }
  .open span:nth-child(1) {
    transform: rotate(-45deg);
    top: 11px;
  }
`;