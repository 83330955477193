import React from 'react';
import styled from 'styled-components'
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"

const Footer = () => {

    const data = useStaticQuery(graphql`
        query{
            file(relativePath: {eq: "gary-website-logo-white.png"}) {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
            }
        }
    `)

    return ( 
        <Copyright>
          <BottomLogo fluid={data.file.childImageSharp.fluid} objectFit="contain"/>  
          <p className="date">© {new Date().getFullYear()}, Gary Hynds</p>
          
        </Copyright>
     );
}
 
export default Footer;

const Copyright = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #0a3b7c;
    color: white;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    .date{
        margin-top: 20px;
    }
    
`

const BottomLogo = styled(Img)`
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
`