import React, { useState } from 'react';
import styled from "styled-components";
import { useSpring, animated, config } from "react-spring";
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import useDocumentScrollThrottled from "./useDocumentScrollThrottled"
import { FaFacebookF, FaTwitter } from "react-icons/fa";


import BurgerMenu from "./burger-menu";
import CollapseMenu from "./collapse-menu";



const Navbar = (props) => {
//   const barAnimation = useSpring({
//     from: { transform: 'translate3d(0, -10rem, 0)' },
//     transform: 'translate3d(0, 0, 0)',
//   });

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 10px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });

  const data = useStaticQuery(graphql`
  query MyQuery {
    file(relativePath: {eq: "gary-website-logo-colour.png"}) {
      id
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
  `)

  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);

  const MINIMUM_SCROLL = 50;
  const TIMEOUT_DELAY = 100;

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowShadow(currentScrollTop > 2);

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const shadowStyle = shouldShowShadow ? 'shadow' : '';
  const hiddenStyle = shouldHideHeader ? 'hidden' : '';

  return (
    <>
      <NavBar className={`header ${shadowStyle} ${hiddenStyle}`}>
        <FlexContainer>
          <Link to="/" style={{width: 80}}>
            <div>   
                <Img style={{width: 60}} objectFit="contain" fluid={data.file.childImageSharp.fluid} />
            </div> 
          </Link>
          <NavLinks style={linkAnimation}>
            <NavLinkButton activeStyle={{ color: "#00aeef", backgroundColor: "#FFFFFF", cursor: "default" }} to="/about">About</NavLinkButton>
            <NavLinkButton activeStyle={{ color: "#00aeef", backgroundColor: "#FFFFFF", cursor: "default" }} to="/blog">Blog</NavLinkButton>
            <NavLinkButton activeStyle={{ color: "#00aeef", backgroundColor: "#FFFFFF", cursor: "default" }} to="/contact">Contact</NavLinkButton>
            <SocialButton style={{ fontSize: "2rem", paddingTop: "11px" }} href="https://www.facebook.com/GaryHyndsLisburn"><FaFacebookF /></SocialButton>
            <SocialButton style={{ fontSize: "2rem", paddingTop: "11px"  }} href="https://twitter.com/GaryHynds"><FaTwitter /></SocialButton>
          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu
              navbarState={props.navbarState} 
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu 
        navbarState={props.navbarState} 
        handleNavbar={props.handleNavbar}
      />
    </>
  )
}

export default Navbar

const NavBar = styled(animated.nav)`
  position: fixed;
  z-index: 30 !important;
  width: 100%;
  top: 0;
  left: 0;
  background: #FFFFFF;
  z-index: 1;
  font-size: 1.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const FlexContainer = styled.div`
  max-width: 125rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;;
  justify-content: space-between;
  height: 5rem;
  aligh-items: center !important;
`;

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  
  
  
`;

const NavLinkButton = styled(Link)`
    color: #0a3b7c;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 .8rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    &:hover {
    color: #00aeef;
    transition: all 300ms linear 0s;
    }
    @media (max-width: 768px) {
    display: none;
    }
`

const SocialButton = styled(NavLinkButton)`
    background-color: #f2f2f2;
    color: #707070;
    &:hover {
        background-color: #00aeef;
        color: #FFFFFF;
        transition: all 300ms linear 0s;
    }
`
 
const BurgerWrapper = styled.div`
  margin: auto 0;
  @media (min-width: 769px) {
    display: none;
  }
`;
